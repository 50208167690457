/* .fonts{
/* color: #fff; */
.header{
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    /* margin-bottom: 20px; */
    /* width: 100%; */
    /* height: 50px; */
}
input.inputBox{
    padding: 5px 8px 4px 8px;
    border-radius: 3px;
}

.containerLync{
    display:flex;
    flex-direction:column;
    gap:10px;
    align-items: flex-start;

}

.modules{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 1.25rem;
    gap: 2rem;

    /* width: 30%; */
    /* width: 31.75rem; */
    min-height: 396px;

    background: #FFFFFF;
    border: 1px solid #E0E0E0;
    border-radius: 0.313rem;

}

tr{
    display: flex;
    /* justify-content: center; */
    /* flex-direction: row; */
    /* align-items: center; */
    /* padding: 0px; */
    /* gap: 10%; */
    width: 100%;

    /* height: 62px; */

    /* flex: none;
    order: 0;
    flex-grow: 0; */
}


/* td{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0px;

    width: 25%;
    height: 58px;
} */