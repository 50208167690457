body {
  margin: 0;
  font-family:'Roboto';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.cursor-pointer{
  cursor: pointer;
}

.primary-button-violet{
  background: #20123A;
  color: #FFFFFF;
  border-radius: 8px !important;
  border: none !important;
  height: 2.5rem;
  padding-inline: 1.25rem !important;
}
.profile-dropdown .dropdown-toggle::after {
  display: none !important;
}

.profile-dropdown .dropdown-item .active {
  background-color: transparent !important;
}

.profile-dropdown .dropdown-menu {
  position: absolute;
  right: 0;
}
.profile-dropdown .dropdown-item.active {
  background-color: transparent !important;
}
.dropdown-toggle-split::after {
  float: right !important;
  margin-top: 0.35rem !important;
}

.dropdown-menu[data-bs-popper] {
  left: unset !important;
}
.min-height-79 {
  min-height: 100vh !important;
}
.profile-collection{
  width: 2.1875rem !important;
  height: 2.1875rem !important;
  border-radius: 1.8125rem !important;
}

