.wrapper{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 5rem 6rem;
    min-width: 65%;
    height:100vh;
    /* margin: 50px 5rem 2.9%; */
    background: #FFFFFF;
    gap: 40px;
}