.navbar_1{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    /* padding: 20px 40px; */

    background: #F2F2F2;
}
.navbarTools{
    display: flex;
    flex-direction: row;
    align-items: center;
    /* padding: 0px; */
    gap: 20px;

    /* width: 539px; */
    /* height: 40px; */


    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
}
